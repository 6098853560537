* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tech-class {
  width: 200px;
  height: 150px;
}

#girl-code {
  animation: girlCode 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes girlCode {
  from {
    transform: rotateZ(12deg);
  }
  to {
    transform: rotateZ(-5deg);
  }
}
#boy-code {
  animation: boyCode 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes boyCode {
  from {
    transform: rotateZ(12deg);
  }
  to {
    transform: rotateZ(-5deg);
  }
}

@media screen and (max-width: 480px) {
  .tech-class {
    width: 100px;
    height: 110px;
  }
}
