* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.data-class {
  width: 200px;
  height: 150px;
}

#for-circle1,
#for-circle2,
#for-circle3,
#for-circle4 {
  animation: forCircle 3s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forCircle {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

#girl-hand {
  animation: forGHand 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forGHand {
  from {
    transform: rotateZ(-20deg);
  }
  to {
    transform: rotateZ(8deg);
  }
}

#boy-hand {
  animation: forBHand 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forBHand {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(7deg);
  }
}

@media screen and (max-width: 480px) {
  .data-class {
    width: 100px;
    height: 110px;
  }
}
