.card {
  width: 80px;
  height: 80px;
  padding: 15px;
  background: #f4f2fb;
  border-radius: 50%;
  /* background-size: cover; */
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 2px solid transparent;
}

.card:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 1800px) {
  .card {
    width: 80px;
    height: 80px;
    padding: 15px;
  }
}

@media screen and (max-width: 1600px) {
  .card {
    width: 65px;
    height: 65px;
    padding: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .card {
    width: 55px !important;
    height: 55px !important;
    padding: 10px !important;
  }
}

@media screen and (max-width: 1300px) {
  .card {
    width: 50px !important;
    height: 50px !important;
    padding: 8px !important;
  }
}

@media screen and (max-width: 1100px) {
  .card {
    width: 50px !important;
    height: 50px !important;
    padding: 8px !important;
  }
}

@media screen and (max-width: 1000px) {
  .card {
    width: 45px !important;
    height: 45px !important;
    padding: 8px !important;
  }
}

@media screen and (max-width: 800px) {
  .card {
    width: 40px !important;
    height: 40px !important;
    padding: 8px !important;
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 480px) {
  .card {
    width: 50px !important;
    height: 50px !important;
    padding: 8px !important;
  }

  .img-src {
    width: 30px;
    height: 30px;
  }
}
