* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.create-class {
  width: 100px;
  height: 120px;
}

#right-hand {
  animation: forRightHand 2s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forRightHand {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(-5deg);
  }
}

#left-hand {
  animation: forLeftHand 2s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forLeftHand {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(3deg);
  }
}

#leg {
  animation: forFCircle 3s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forFCircle {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(-10deg);
  }
}

@media screen and (max-width: 480px) {
  .create-class {
    width: 100px;
    height: 120px;
  }
}
