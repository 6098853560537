a:active,
a:focus,
a:hover {
  outline: 0;
  color: transparent;
  border: none;
}

a:visited {
  outline: none;
}

.main-title {
  display: flex;
  /* width: 80%; */
  /* margin-right: auto; */
  /* margin-left: auto; */
  flex-wrap: wrap;
  height: 90vh;
  background-color: #9684ca;
  opacity: 0.8;
  position: relative;
}

.design {
  display: flex;
  /* background-color: crimson; */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  background-color: #fff;
  width: 70%;
  height: 25vh;
  margin-top: 20vh;
  border: 1px solid #fff;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  position: relative;
  box-shadow: 20px 20px 50px #735eb1;
  opacity: 1;
}

.logo-img {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(20%, -50%);
  width: 280px;
  height: 160px;
  /* display: block; */
  margin-left: auto;
  margin-right: 100px;
}

.hr-tag {
  border-left: 3px solid #9533be;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(0%, -50%);
  border-radius: 48px;
  /* position: absolute; */
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  color: #9533be;
  /* width: "330px"; */
  /* height: 200px; */
  /* display: block; */
  margin-left: auto;
  margin-right: 100px;
  font-weight: 500;
  font-size: 50px;
  /* justify-content: space-between; */
  /* word-spacing: 1cm; */
}

.pi-svg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.intro-main {
  height: 70vh;
  background-color: #b7acd8;
  position: relative;
}

/* .intro-text { */
/* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  width: 400px; */
/* height: 200px; */
/* margin-left: auto; */
/* margin-right: 100px; */
/* } */

.intro-h {
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(60%, -50%);
  width: 450px;
  color: #5e4d8a;
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.intro-p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(20%, -50%);
  width: 700px;
  color: rgb(236, 231, 231);
  font-size: 25px;
  text-align: left;
  justify-content: center;

  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.hr-intro-tag {
  border-left: 3px solid #9533be;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  border-radius: 48px;
  /* position: absolute; */
}

.serve-main {
  height: 70vh;
  background-color: #c3badf;
  position: relative;
}

.serve-h {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(80%, -50%);
  width: 400px;
  color: #5e4d8a;
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.serve-p {
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(20%, -50%);
  width: 700px;
  font-size: 25px;
  color: rgb(236, 231, 231);
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.hr-serve-tag {
  border-left: 3px solid #9533be;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  border-radius: 48px;
  /* position: absolute; */
}

.tech-main {
  height: 70vh;
  background-color: #d0c9e7;
  position: relative;
}

.tech-h {
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(55%, -50%);
  width: 450px;
  color: #5e4d8a;
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.tech-p,
.tech-p2 {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(60%, -50%);
  width: 600px;
  justify-content: space-between;
  color: rgb(236, 231, 231);
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.tech-p-2 {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(50%, -50%);
  width: 600px;
  justify-content: space-between;
  color: #5e4d8a;
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.frontend {
  text-align: center;
}

.fr-h {
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #6a5896;
  font-weight: bold;
  /* text-decoration: underline; */
  font-size: small;
  border: 1px solid #fff;
  background-color: #fff;
  padding: 10px;
  border-radius: 22px;
  width: 140px;
  animation: notScaling 1s 1 normal forwards;
}

.fr-h-scale {
  animation: scaling 1s 1 normal forwards;
}

.fr-h4:hover {
  animation: scaling 1s 1 normal forwards;
}

.fr-h:active {
  transform: scale(1.09);
}

.fr-h:hover {
  animation: scaling 1s 1 normal forwards;
}

.fr-h3 {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  color: #8e7db8;
  font-size: 32px;
  /* border: 1px solid #fff; */
  /* background-color: #fff; */
  padding: 5px;
  border-radius: 22px;
  width: 100%;
  animation: notScaling 1s 1 normal forwards;
}

.fr-h4 {
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #5e4d8a;
  /* text-decoration: underline; */
  font-size: small;
  border: 1px solid #fff;
  /* background-color: #fff; */
  padding: 10px;
  border-radius: 11px;
  width: 200px;
  background-color: #fff;
  font-weight: bold;
  animation: notScaling 1s 1 normal forwards;
}

.fr-h4-scale {
  animation: scaling 1s 1 normal forwards;
}

.our-head {
  font-size: 22px;
}

.prod-head {
  font-size: 56px;
}

.prod-h2-mob {
  display: none;
}

.fr-h3:active {
  transform: scale(1.1);
}

.fr-h3:hover {
  animation: scaling 1s 1 normal forwards;
  /* color: crimson; */
}

/* .fr-h :not(:hover) {
  animation: notScaling 1s 1;
  transform-box: fill-box;
  transform-origin: center;
  animation-iteration-count: 1;
} */

@keyframes scaling {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.09);
  }
}

@keyframes scalingForTeam {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.09);
  }
}

@keyframes notScaling {
  from {
    transform: scale(1.09);
  }
  to {
    transform: scale(1);
  }
}

@keyframes notScalingForTeam {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}

.front-tech {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  justify-content: space-evenly;
  color: rgb(236, 231, 231);
  animation: fadeIn 1s 1;
}

.front-tech-2 {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  color: rgb(236, 231, 231);
  animation: fadeIn 1s 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.backend {
  text-align: center;
}

.be-h {
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #6a5896;
  /* text-decoration: underline; */
  font-size: small;
  border: 1px solid #fff;
  background-color: #fff;
  padding: 10px;
  border-radius: 22px;
  width: 140px;
  animation: notScaling 1s 1 normal forwards;
}

.be-h:hover {
  animation: scaling 1s 1 normal forwards;
}

.be-h-scale {
  animation: scaling 1s 1 normal forwards;
}

.back-tech {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  justify-content: space-evenly;
  color: rgb(236, 231, 231);
  animation: fadeIn 1s 1;
}

.db {
  text-align: center;
}

.db-h {
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #6a5896;
  /* text-decoration: underline; */
  font-size: small;
  border: 1px solid #fff;
  background-color: #fff;
  padding: 10px;
  border-radius: 22px;
  width: 140px;
  animation: notScaling 1s 1 normal forwards;
}

.db-h:hover {
  animation: scaling 1s 1 normal forwards;
}

.db-h-scale {
  animation: scaling 1s 1 normal forwards;
}

.db-tech {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  justify-content: space-evenly;
  color: rgb(236, 231, 231);
  animation: fadeIn 1s 1;
}

.gd {
  text-align: center;
}

.gd-h {
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #6a5896;
  /* text-decoration: underline; */
  font-size: small;
  border: 1px solid #fff;
  background-color: #fff;
  padding: 10px;
  border-radius: 22px;
  width: 140px;
  animation: notScaling 1s 1 normal forwards;
}

.gd-h:hover {
  animation: scaling 1s 1 normal forwards;
}

.gd-h-scale {
  animation: scaling 1s 1 normal forwards;
}

.gd-tech {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  justify-content: space-evenly;
  color: rgb(236, 231, 231);
  animation: fadeIn 1s 1;
}

.hr-tech-tag {
  border-left: 3px solid #9533be;
  height: 300px;
  position: absolute;
  /* color:rgb(46, 43, 43); */
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  border-radius: 48px;
  /* position: absolute; */
}

.prod-main {
  height: 70vh;
  background-color: #d8d2e9;
  position: relative;
}

.prod-text {
  display: flex;
}

.prod-h {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 20%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: #5e4d8a;
  text-align: center;
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.prod-h2 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: #5e4d8a;
  text-align: center;
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.prod-h3 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 80%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: #5e4d8a;
  text-align: center;
  /* height: 200px; */
  /* margin-left: auto; */
  /* margin-right: 100px; */
}

.prod-p {
  /* border-top: 1px solid #9533be; */
  /* border-bottom: 1px solid #9533be; */
  /* background-color: #d8d2e9; */
  /* height: 70vh; */
  position: relative;
}

.first-proj {
  text-align: center;
}

.first-title {
  font-size: 42px;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  color: #9533be;
}

.first-fur {
  position: relative;
  height: 50vh;
}

.first-p {
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(50%, -50%);
  width: 440px;
  color: #5e4d8a;
}

.first-imgs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  width: 440px;
  color: #5e4d8a;
}

.first-img-web {
  width: 400px;
  height: 200px;
  border-radius: 12px;
}

.first-img-mob {
  width: 200px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}

.hr-first-tag {
  border-left: 3px solid #9533be;
  height: 300px;
  position: absolute;
  /* color:rgb(46, 43, 43); */
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  border-radius: 48px;
  /* position: absolute; */
}

/* .hr-first-tag:hover {
  border-left: 3px solid #9533be;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  border-radius: 48px;
} */

.btn-port {
  border: 1px solid #9533be;
  border-radius: 22px;
  background-color: transparent;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #9533be;
  outline: none;
}

.btn-port-disabled {
  border: 1px solid grey;
  border-radius: 22px;
  background-color: transparent;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: grey;
  outline: none;
}

.btn-port:hover {
  outline: none;
  border: 1px solid #9533be;
  background-color: #9533be;
  color: #fff;
}

.btn-port:focus {
  outline: none;
}

.first-proj-tech {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  text-align: center;
  width: 500px;
  color: #5e4d8a;
}

.first-project {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* border: 1px solid rgb(235, 233, 241); */
  /* background-color: rgb(235, 233, 241); */
  /* box-shadow: 20px 20px 50px rgb(178, 174, 190); */
  /* border-radius: 22px; */
  /* padding: 50px; */
}

.first-proj-title {
  color: #9533be;
}

.first-proj-p {
  color: #b98ccc;
}

.first-proj-img {
  width: 100%;
  opacity: 0.5;
  object-fit: cover;
  /* height: 70vh; */
}

.team-main {
  height: 100vh;
  background-color: #dcd7e9;
  text-align: center;
}

.team-h {
  width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  color: #5e4d8a;
  /* top:50% */
}

.team-h-p {
  width: 600px;
  color: #7a6c9c;
  margin-left: auto;
  margin-right: auto;
}

.team-members-div {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
}

.team-members {
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: #6a5896;
  font-weight: bold;
  /* text-decoration: underline; */
  font-size: small;
  /* border: 1px solid #fff; */
  background-color: #fff;
  border-radius: 8px;
  width: 450px;
  height: 150px;
  display: flex;
  animation: notScalingForTeam 1s 1 normal forwards;
}

.team-members:hover {
  animation: scalingForTeam 1s 1 normal forwards;
}

.team-img {
  width: 130px;
  height: 130px;
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 10px;
}

.team-details {
  display: flex;
  flex-direction: column;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-28%, -50%);
}

.team-text {
  width: 300px;
  font-size: 22px;
  margin-top: 10px;
  color: #5e4d8a;
  font-weight: bold;
  padding-bottom: 0;
  margin-bottom: 0;
}

.team-p {
  color: #aea0ce;
  font-size: 18px;
  margin-top: 0;
  padding-top: 0;
}

@media screen and (max-width: 1800px) {
  .main-title {
    height: 92vh;
  }

  .design {
    width: 70%;
    height: 25vh;
    /* margin-top: 20vh; */
    /* border: 1px solid #fff; */
    /* border-top-right-radius: 80px; */
    /* border-bottom-right-radius: 80px; */
    /* position: relative; */
    /* box-shadow: 20px 20px 50px #735eb1; */
  }

  .hr-tag {
    height: 90px;
  }

  .logo-img {
    width: 250px;
    height: 150px;
  }

  .intro-main {
    height: 80vh;
    background-color: #b7acd8;
    position: relative;
    /* background-color: crimson; */
  }

  .intro-p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(15%, -50%);
    width: 700px;
    color: rgb(236, 231, 231);
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-intro-tag {
    border-left: 3px solid #9533be;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    border-radius: 48px;
    /* position: absolute; */
  }

  .serve-main {
    height: 80vh;
    background-color: #c3badf;
    position: relative;
    /* background-color: blue; */
  }

  .serve-h {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(70%, -50%);
    width: 400px;
    color: #5e4d8a;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-p {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(13%, -50%);
    width: 700px;
    color: rgb(236, 231, 231);
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-serve-tag {
    border-left: 3px solid #9533be;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    border-radius: 48px;
    /* position: absolute; */
  }

  .tech-main {
    height: 80vh;
    background-color: #d0c9e7;
    position: relative;
  }

  .tech-h {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(55%, -50%);
    width: 450px;
    color: #5e4d8a;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-p,
  .tech-p2 {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(50%, -50%);
    width: 600px;
    justify-content: space-between;
    color: rgb(236, 231, 231);
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .fr-h,
  .be-h,
  .db-h,
  .gd-h {
    width: 120px;
    font-size: 12px;
  }

  .prod-main {
    height: 80vh;
  }
}

@media screen and (max-width: 1600px) {
  .main-title {
    height: 94vh;
  }

  .design {
    width: 80%;
    height: 25vh;
    /* margin-top: 20vh; */
    /* border: 1px solid #fff; */
    /* border-top-right-radius: 80px; */
    /* border-bottom-right-radius: 80px; */
    /* position: relative; */
    /* box-shadow: 20px 20px 50px #735eb1; */
  }

  .hr-tag {
    top: 54%;
    left: 45%;
    transform: translate(0%, -60%);
    height: 90px;
  }

  .logo-img {
    width: 260px;
    height: 150px;
  }

  .intro-main {
    height: 80vh;
    background-color: #b7acd8;
    position: relative;
    /* background-color: blue; */
  }

  .intro-h {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(20%, -50%);
    width: 450px;
    color: #5e4d8a;
  }

  .intro-p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(15%, -50%);
    width: 550px;
    color: rgb(236, 231, 231);
    font-size: 24px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-intro-tag {
    border-left: 3px solid #9533be;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    border-radius: 48px;
    /* position: absolute; */
  }

  .serve-main {
    height: 80vh;
    background-color: #c3badf;
    position: relative;
    /* background-color: blue; */
  }

  .serve-h {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(60%, -50%);
    width: 400px;
    color: #5e4d8a;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-p {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(13%, -50%);
    width: 550px;
    color: rgb(236, 231, 231);
    font-size: 24px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-serve-tag {
    border-left: 3px solid #9533be;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    border-radius: 48px;
    /* position: absolute; */
  }

  .tech-main {
    height: 80vh;
    background-color: #d0c9e7;
    position: relative;
  }

  .tech-h {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(20%, -50%);
    width: 450px;
    color: #5e4d8a;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-p {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(50%, -50%);
    width: 450px;
    justify-content: space-between;
    color: rgb(236, 231, 231);
    font-size: 16px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-p2 {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(40%, -50%);
    width: 500px;
    justify-content: space-between;
    color: rgb(236, 231, 231);
    font-size: 16px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .front-tech,
  .back-tech,
  .db-tech,
  .gd-tech {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    color: rgb(236, 231, 231);
    /* background-color: khaki; */
  }

  .fr-h,
  .be-h,
  .db-h,
  .gd-h {
    width: 140px;
    font-size: 12px;
  }

  .prod-main {
    height: 80vh;
    background-color: #d8d2e9;
    position: relative;
  }

  .prod-text {
    display: flex;
  }

  .prod-h {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 20%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .fr-h3 {
    color: #8e7db8;
    font-size: 26px;
  }

  .fr-h4 {
    font-size: 18px;
    width: 250px;
  }

  .our-head {
    font-size: 20px;
  }

  .prod-head {
    font-size: 48px;
  }

  .prod-h2 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .prod-h3 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 80%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .prod-p {
    /* border-top: 1px solid #9533be; */
    /* border-bottom: 1px solid #9533be; */
    /* background-color: #d8d2e9; */
    /* height: 70vh; */
    position: relative;
  }

  .first-proj {
    text-align: center;
  }

  .first-title {
    font-size: 42px;
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    color: #9533be;
  }

  .first-fur {
    position: relative;
    height: 50vh;
  }

  .first-p {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(50%, -50%);
    width: 440px;
    color: #5e4d8a;
  }

  .first-imgs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    width: 440px;
    color: #5e4d8a;
  }

  .first-img-web {
    width: 400px;
    height: 200px;
    border-radius: 12px;
  }

  .first-img-mob {
    width: 200px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
}

@media screen and (max-width: 1300px) {
  .main-title {
    height: 94vh;
  }

  .design {
    width: 92%;
    height: 28vh;
    /* margin-top: 20vh; */
    /* border: 1px solid #fff; */
    /* border-top-right-radius: 80px; */
    /* border-bottom-right-radius: 80px; */
    /* position: relative; */
    /* box-shadow: 20px 20px 50px #735eb1; */
  }

  .hr-tag {
    height: 90px;
  }

  .logo-img {
    width: 220px;
    height: 130px;
  }

  .text {
    font-size: 38px;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(0%, -50%);
    color: #9533be;
    /* width: "330px"; */
    /* height: 200px; */
    /* display: block; */
    margin-left: auto;
    margin-right: 100px;
    /* justify-content: space-between; */
    /* word-spacing: 1cm; */
  }

  .intro-main {
    height: 85vh;
    background-color: #b7acd8;
    position: relative;
    /* background-color: blue; */
  }

  .intro-h {
    top: 50%;
    /* left: 50%; */
    transform: translate(50%, -50%);
    width: 300px;
    color: #5e4d8a;
    font-size: 28px;
  }

  .intro-p {
    top: 50%;
    left: 50%;
    transform: translate(15%, -50%);
    width: 500px;
    color: rgb(236, 231, 231);
    font-size: 20px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-main {
    height: 80vh;
    background-color: #c3badf;
    position: relative;
    /* background-color: blue; */
  }

  .serve-h {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    width: 400px;
    font-size: 28px;
    color: #5e4d8a;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-p {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    font-size: 28px;
    transform: translate(10%, -50%);
    width: 500px;
    color: rgb(236, 231, 231);
    font-size: 20px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-serve-tag {
    border-left: 3px solid #9533be;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    border-radius: 48px;
    /* position: absolute; */
  }

  .serve-main {
    height: 80vh;
    background-color: #c3badf;
    position: relative;
    /* background-color: crimson; */
  }

  .serve-h {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(55%, -50%);
    width: 300px;
    text-align: center;
    font-size: 28px;
    color: #5e4d8a;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-p {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    font-size: 28px;
    transform: translate(30%, -50%);
    width: 400px;
    color: rgb(236, 231, 231);
    font-size: 18px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-serve-tag {
    border-left: 3px solid #9533be;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    border-radius: 48px;
    /* position: absolute; */
  }

  .tech-main {
    height: 80vh;
    background-color: #d0c9e7;
    position: relative;
  }

  .tech-h {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(40%, -50%);
    width: 450px;
    color: #5e4d8a;
    font-size: 28px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-p {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(35%, -50%);
    width: 500px;
    justify-content: space-between;
    color: rgb(236, 231, 231);
    font-size: 16px;
    /* font-size: 28px; */
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-p2 {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(25%, -50%);
    width: 500px;
    justify-content: space-between;
    color: rgb(236, 231, 231);
    font-size: 16px;
    /* font-size: 28px; */
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .prod-main {
    height: 85vh;
    background-color: #d8d2e9;
    position: relative;
  }

  .prod-text {
    display: flex;
  }

  .prod-h {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 20%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .fr-h3 {
    color: #8e7db8;
    font-size: 24px;
  }

  .fr-h4 {
    font-size: 14px;
    width: 200px;
  }

  .our-head {
    font-size: 14px;
  }

  .prod-head {
    font-size: 28px;
  }

  .prod-h2 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .prod-h3 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 70%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }
}

@media screen and (max-width: 1100px) {
  .main-title {
    height: 98vh;
  }

  .design {
    width: 100%;
    height: 28vh;
    /* margin-top: 20vh; */
    /* border: 1px solid #fff; */
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* position: relative; */
    /* box-shadow: 20px 20px 50px #735eb1; */
  }

  .logo-img {
    width: 220px;
    height: 130px;
  }

  .hr-tag {
    height: 90px;
  }

  .text {
    font-size: 38px;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(0%, -50%);
    color: #9533be;
    /* width: "330px"; */
    /* height: 200px; */
    /* display: block; */
    margin-left: auto;
    margin-right: 100px;
    /* justify-content: space-between; */
    /* word-spacing: 1cm; */
  }

  .intro-main {
    height: 85vh;
    background-color: #b7acd8;
    position: relative;
  }

  .intro-h {
    top: 50%;
    /* left: 50%; */
    transform: translate(50%, -50%);
    width: 300px;
    color: #5e4d8a;
    font-size: 24px;
  }

  .intro-p {
    top: 50%;
    left: 50%;
    transform: translate(18%, -50%);
    width: 400px;
    color: rgb(236, 231, 231);
    font-size: 18px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-main {
    height: 85vh;
    background-color: #c3badf;
    position: relative;
    /* background-color: blue; */
  }

  .serve-h {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    width: 300px;
    text-align: center;
    font-size: 24px;
    color: #5e4d8a;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-p {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    font-size: 24px;
    transform: translate(20%, -50%);
    width: 400px;
    color: rgb(236, 231, 231);
    font-size: 24px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-serve-tag {
    border-left: 3px solid #9533be;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    border-radius: 48px;
    /* position: absolute; */
  }

  .tech-main {
    height: 85vh;
    background-color: #d0c9e7;
    position: relative;
    /* background-color: crimson; */
  }

  .tech-h {
    top: 50%;
    /* left: 50%; */
    transform: translate(50%, -50%);
    width: 300px;
    color: #5e4d8a;
    font-size: 24px;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-p {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(25%, -50%);
    width: 450px;
    justify-content: space-between;
    color: rgb(236, 231, 231);
    font-size: 16px;
    flex-wrap: wrap;
    /* background-color: crimson; */
    /* font-size: 28px; */
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-p2 {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(15%, -50%);
    width: 500px;
    justify-content: space-between;
    color: rgb(236, 231, 231);
    font-size: 16px;
    flex-wrap: wrap;
    /* background-color: crimson; */
    /* font-size: 28px; */
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .front-tech,
  .back-tech,
  .db-tech,
  .gd-tech {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    width: 500px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    color: rgb(236, 231, 231);
    /* background-color: khaki; */
  }

  .prod-main {
    height: 85vh;
    background-color: #d8d2e9;
    position: relative;
  }

  .prod-text {
    display: flex;
  }

  .prod-h {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 20%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .fr-h3 {
    color: #8e7db8;
    font-size: 16px;
  }

  .fr-h4 {
    font-size: 12px;
    width: 180px;
  }

  .our-head {
    font-size: 12px;
  }

  .prod-head {
    font-size: 24px;
  }

  .prod-h2 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .prod-h3 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 70%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }
}

@media screen and (max-width: 1000px) {
  .main-title {
    /* display: flex; */
    /* width: 80%; */
    /* margin-right: auto; */
    /* margin-left: auto; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    height: 100vh;
    background-color: #9684ca;
  }

  .design {
    width: 90%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    /* margin-top: 20vh; */
    border: 1px solid #fff;
    border-radius: 30px;
    /* border-bottom-right-radius: 80px; */
    box-shadow: 20px 20px 50px #735eb1;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    /* position: relative; */
    /* position: absolute; */
  }

  .logo-img {
    margin-left: auto;
    margin-right: auto;
    /* font-size: 42px; */
    position: absolute;
    /* top: 50%; */
    /* left: 0%; */
    /* transform: translate(0%, 50%); */
    /* top: 100%; */
    /* left: 55%; */
    /* transform: translate(0%, -50%); */
    /* display: none; */
  }

  .hr-tag {
    border-left: 2px solid #9533be;
    height: 70px;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
  }

  .text {
    margin-left: auto;
    margin-right: auto;
    font-size: 32px;

    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(0%, -50%);
    /* top: 50%; */
    /* transform: translate(0%, -50%); */
    /* justify-content: space-between; */
    /* word-spacing: 1cm; */
  }

  .intro-main {
    height: 100vh;
    background-color: #b7acd8;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .intro-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 200px;
    color: #5e4d8a;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .intro-p {
    margin-top: 20px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 24px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-intro-tag {
    display: none;
    /* border-left: 3px solid #9533be;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  border-radius: 48px; */
  }

  .serve-main {
    height: 100vh;
    background-color: #c3badf;
    /* position: relative; */
    display: flex;
    flex-direction: column !important;
    text-align: center;
    justify-content: center;
    /* background-color: blue; */
  }

  .serve-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 200px;
    color: #5e4d8a;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .serve-p {
    margin-top: 20px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 24px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-serve-tag {
    display: none;
  }

  .tech-main {
    height: 100vh;
    background-color: #d0c9e7;
    /* position: relative; */
    display: flex;
    flex-direction: column-reverse !important;
    text-align: center;
    justify-content: center;
    /* background-color: blue; */
  }

  .tech-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 250px;
    color: #5e4d8a;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .tech-p,
  .tech-p2 {
    margin-top: 40px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 24px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-tech-tag {
    display: none;
  }

  .fr-h,
  .be-h,
  .db-h,
  .gd-h {
    width: 120px;
    font-size: 10px;
  }

  .front-tech,
  .back-tech,
  .db-tech,
  .gd-tech {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    width: 700px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    color: rgb(236, 231, 231);
    /* background-color: khaki; */
  }

  .prod-main {
    height: 100vh;
    background-color: #d8d2e9;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .prod-text {
    display: flex;
  }

  .prod-h {
    margin: 0;
    position: initial;
    top: 0%;
    left: 0%;
    margin-right: 0%;
    transform: translate(0%, 0%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }

  .fr-h3 {
    color: #8e7db8;
    font-size: 14px;
  }

  .fr-h4 {
    font-size: 12px;
    width: 160px;
  }

  .our-head {
    font-size: 10px;
  }

  .prod-head {
    font-size: 20px;
  }

  .prod-h2 {
    display: none;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .prod-h2-mob {
    display: initial;
    margin: 0;
    position: initial;
    top: 0%;
    left: 0%;
    margin-right: 0%;
    transform: translate(0%, 0%);
    color: #5e4d8a;
    text-align: center;
    margin-bottom: 40px;
  }

  .prod-h3 {
    margin: 0;
    position: initial;
    top: 0%;
    left: 0%;
    margin-right: 0%;
    transform: translate(0%, 0%);
    color: #5e4d8a;
    text-align: center;
    /* height: 200px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
}

@media screen and (max-width: 800px) {
  .main-title {
    /* display: flex; */
    /* width: 80%; */
    /* margin-right: auto; */
    /* margin-left: auto; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    height: 100vh;
    background-color: #9684ca;
  }

  .design {
    width: 95%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    /* margin-top: 20vh; */
    border: 1px solid #fff;
    border-radius: 30px;
    /* border-bottom-right-radius: 80px; */
    box-shadow: 20px 20px 50px #735eb1;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    /* position: relative; */
    /* position: absolute; */
  }

  .logo-img {
    margin-left: auto;
    margin-right: auto;
    /* font-size: 42px; */
    position: absolute;

    top: 50%;
    left: 8%;
    transform: translate(20%, -50%);
    width: 200px;
    height: 120px;
    /* display: block; */
    margin-left: auto;
    margin-right: 100px;
  }

  .hr-tag {
    border-left: 2px solid #9533be;
    height: 55px;
    top: 50%;
    left: 48%;
    transform: translate(0%, -50%);
  }

  .text {
    margin-left: auto;
    margin-right: auto;
    font-size: 28px;

    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(0%, -50%);
    /* top: 50%; */
    /* transform: translate(0%, -50%); */
    /* justify-content: space-between; */
    /* word-spacing: 1cm; */
  }

  .intro-main {
    height: 100vh;
    background-color: #b7acd8;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    /* background-color: blue; */
  }

  .intro-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 200px;
    color: #5e4d8a;
    font-size: 16px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .intro-p {
    margin-top: 20px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 18px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-main {
    height: 100vh;
    background-color: #c3badf;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    /* background-color: blue; */
  }

  .serve-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 200px;
    color: #5e4d8a;
    font-size: 16px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .serve-p {
    margin-top: 20px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 18px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-main {
    height: 120vh;
    background-color: #d0c9e7;
    /* position: relative; */
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    overflow: scroll;
    /* background-color: blue; */
  }

  .tech-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 250px;
    color: #5e4d8a;
    font-size: 16px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .tech-p,
  .tech-p2 {
    margin-top: 40px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 18px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* background-color: blue; */
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-tech-tag {
    display: none;
  }

  .fr-h,
  .be-h,
  .db-h,
  .gd-h {
    width: 100px;
    font-size: 10px;
    padding: 7px;
  }

  .front-tech,
  .back-tech,
  .db-tech,
  .gd-tech {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    color: rgb(236, 231, 231);
    /* background-color: khaki; */
  }
}
@media screen and (max-width: 700px) {
  .main-title {
    /* display: flex; */
    /* width: 80%; */
    /* margin-right: auto; */
    /* margin-left: auto; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    height: 100vh;
    background-color: #9684ca;
  }

  .design {
    width: 95%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    /* margin-top: 20vh; */
    border: 1px solid #fff;
    border-radius: 30px;
    /* border-bottom-right-radius: 80px; */
    box-shadow: 20px 20px 50px #735eb1;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    /* position: relative; */
    /* position: absolute; */
  }

  .logo-img {
    margin-left: auto;
    margin-right: auto;
    /* font-size: 42px; */
    position: absolute;

    top: 50%;
    left: 8%;
    transform: translate(20%, -50%);
    width: 250px;
    height: 120px;
    /* display: block; */
    margin-left: auto;
    margin-right: 100px;
  }

  .hr-tag {
    border-left: 2px solid #9533be;
    height: 55px;
    top: 50%;
    left: 48%;
    transform: translate(0%, -50%);
  }

  .text {
    margin-left: auto;
    margin-right: auto;
    font-size: 28px;

    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(0%, -50%);
    /* top: 50%; */
    /* transform: translate(0%, -50%); */
    /* justify-content: space-between; */
    /* word-spacing: 1cm; */
  }

  .intro-main {
    height: 100vh;
    background-color: #b7acd8;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    /* background-color: blue; */
  }

  .intro-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 200px;
    color: #5e4d8a;
    font-size: 16px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .intro-p {
    margin-top: 20px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 18px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-main {
    height: 100vh;
    background-color: #c3badf;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    /* background-color: blue; */
  }

  .serve-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 200px;
    color: #5e4d8a;
    font-size: 16px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .serve-p {
    margin-top: 20px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 18px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-main {
    height: 120vh;
    background-color: #d0c9e7;
    /* position: relative; */
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    overflow: scroll;
    /* background-color: blue; */
  }

  .tech-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 250px;
    color: #5e4d8a;
    font-size: 16px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .tech-p,
  .tech-p2 {
    margin-top: 40px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 70%;
    color: rgb(236, 231, 231);
    font-size: 18px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* background-color: blue; */
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-tech-tag {
    display: none;
  }

  .fr-h,
  .be-h,
  .db-h,
  .gd-h {
    width: 100px;
    font-size: 10px;
    padding: 7px;
  }

  .front-tech,
  .back-tech,
  .db-tech,
  .gd-tech {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    color: rgb(236, 231, 231);
    /* background-color: khaki; */
  }
}

@media screen and (max-width: 600px) {
  .main-title {
    /* display: flex; */
    /* width: 80%; */
    /* margin-right: auto; */
    /* margin-left: auto; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    height: 100vh;
    background-color: #9684ca;
  }

  .design {
    width: 98%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    /* margin-top: 20vh; */
    border: 1px solid #fff;
    border-radius: 30px;
    /* border-bottom-right-radius: 80px; */
    box-shadow: 20px 20px 50px #735eb1;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    /* position: relative; */
    /* position: absolute; */
  }

  .logo-img {
    margin-left: auto;
    margin-right: auto;
    /* font-size: 42px; */
    position: absolute;

    top: 50%;
    left: 8%;
    transform: translate(20%, -50%);
    width: 130px;
    height: 80px;
    /* display: block; */
    margin-left: auto;
    margin-right: 100px;
  }

  .hr-tag {
    border-left: 1px solid #9533be;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
  }

  .text {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;

    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(0%, -50%);
    /* top: 50%; */
    /* transform: translate(0%, -50%); */
    /* justify-content: space-between; */
    /* word-spacing: 1cm; */
  }

  .fr-h3 {
    color: #8e7db8;
    font-size: 8px;
  }

  .fr-h4 {
    font-size: 12px;
  }

  .our-head {
    font-size: 8px;
  }

  .prod-head {
    font-size: 16px;
  }
  .prod-h3 {
    margin-top: 25px;
  }
  .prod-h2-mob {
    margin-bottom: 25px;
  }
  .prod-h {
    margin-top: 25px;
  }
}

@media screen and (max-width: 480px) {
  .main-title {
    /* display: flex; */
    /* width: 80%; */
    /* margin-right: auto; */
    /* margin-left: auto; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    height: 100vh;
    background-color: #9684ca;
  }

  .design {
    width: 95%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    /* margin-top: 20vh; */
    border: 1px solid #fff;
    border-radius: 30px;
    /* border-bottom-right-radius: 80px; */
    box-shadow: 20px 20px 50px #735eb1;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    /* position: relative; */
    /* position: absolute; */
  }

  .logo-img {
    margin-left: auto;
    margin-right: auto;
    /* font-size: 42px; */
    position: absolute;

    top: 40%;
    left: 8%;
    transform: translate(20%, -50%);
    width: 200px;
    height: 130px;
    /* display: block; */
    margin-left: auto;
    margin-right: 100px;
  }

  .hr-tag {
    display: none;
    /* border-bottom: 1px solid #9533be; */
    /* height: 30px; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(0%, -50%); */
  }

  .text {
    margin-left: auto;
    margin-right: auto;
    font-size: 22px;
    position: absolute;
    top: 70%;
    left: 27%;
    transform: translate(0%, -50%);
    /* top: 50%; */
    /* transform: translate(0%, -50%); */
    /* justify-content: space-between; */
    /* word-spacing: 1cm; */
  }

  .intro-main {
    height: 100vh;
    background-color: #b7acd8;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    /* background-color: blue; */
  }

  .intro-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 100%;
    color: #5e4d8a;
    font-size: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .intro-p {
    margin-top: 20px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 95%;
    color: rgb(236, 231, 231);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .serve-main {
    height: 100vh;
    background-color: #c3badf;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .serve-text {
    display: flex;
    flex-direction: column-reverse;
  }

  .serve-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 100%;
    color: #5e4d8a;
    font-size: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .serve-p {
    margin-top: 0px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 95%;
    color: rgb(236, 231, 231);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-main {
    height: 100vh;
    background-color: #d0c9e7;
    /* position: relative; */
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    overflow: scroll;
    /* background-color: blue; */
  }

  .tech-h {
    position: initial;
    top: 0%;
    /* left: 50%; */
    transform: translate(0%, 0%);
    width: 100%;
    color: #5e4d8a;
    font-size: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .tech-p {
    margin-top: 40px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 95%;
    color: rgb(236, 231, 231);
    font-size: 22px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* background-color: blue; */
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .tech-p2 {
    margin-top: 40px;
    position: initial;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 95%;
    color: rgb(236, 231, 231);
    font-size: 22px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    /* height: 200px; */
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }

  .hr-tech-tag {
    display: none;
  }

  .fr-h,
  .be-h,
  .db-h,
  .gd-h {
    width: 200px;
    font-size: 18px;
    padding: 8px;
  }

  .front-tech,
  .back-tech,
  .db-tech,
  .gd-tech {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    /* background-color: khaki; */
  }

  .fr-h3 {
    color: #8e7db8;
    font-size: 18px;
  }

  .fr-h4 {
    width: 230px;
    font-size: 18px;
    padding: 8px;
  }

  .our-head {
    font-size: 18px;
  }

  .prod-head {
    font-size: 30px;
  }

  .prod-h3 {
    margin-top: 20px;
  }
  .prod-h2-mob {
    margin-bottom: 20px;
  }
  .prod-h {
    margin-top: 20px;
  }

  .prod-p {
    height: 100vh;
  }

  .first-proj-img {
    /* width: 100%; */
    opacity: 0.5;
    /* width: auto; */
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

  .prod-text {
    flex-direction: column;
  }
}
