.know-more,
.games-library,
.privacy,
.bring-your-friends {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.2s;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  border-radius: 28px;
  padding: 30px;
  border-color: transparent;
  cursor: pointer;
  width: 300px;
  height: 330px;
  margin-top: 5px;
  background-color: #9989c8;
}

.know-more:hover,
.games-library:hover,
.privacy:hover,
.bring-your-friends:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.2s;
  transform: scale(1.03);
  background-color: #8a78c0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 28px;
  padding: 30px;
  border-color: transparent;
  cursor: pointer;
  width: 300px;
  margin-top: 5px;
}

.know-more:hover .know-image,
.games-library:hover .gl-image,
.privacy:hover .privacy-image,
.bring-your-friends:hover .byf-image {
  visibility: visible;
  animation-name: pic-animation;
  animation-duration: 2s;
  animation-delay: -0.9s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes pic-animation {
  /* 100% {
    margin-left: 0px;
  } */

  0% {
    margin-left: 150px;
  }
  25% {
    margin-left: 100px;
  }
  /* 75% {
    margin-left: 50px;
  } */
  100% {
    margin-left: 10px;
  }
}

.arrow {
  margin-right: 1px;
}

.know-learn,
.gl-learn,
.privacy-learn,
.byf-learn {
  font-size: 12px;
  margin-top: auto;
}

.know-learn:hover .arrow,
.gl-learn:hover .arrow,
.privacy-learn:hover .arrow,
.byf-learn:hover .arrow {
  animation-name: arrow-animation;
  animation-duration: 2s;
  animation-delay: -1s;
  animation-timing-function: ease;
  animation-direction: reverse;
}

@keyframes arrow-animation {
  50% {
    margin-left: 10px;
  }
  50% {
    margin-right: 5px;
  }
}

.know-image,
.gl-image,
.privacy-image,
.byf-image {
  margin-left: auto;
  /* visibility: hidden; */
}

.socials-mail {
  margin-left: 30px;
  text-align: left;
  /* background-color: coral; */
}

.socials-media {
  margin-right: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: auto;
}

.social-tag {
  margin-right: 30px;
}

.mail-logo {
  color: #b7acd8;
  font-size: 28px;
  transition: transform 0.1s;
  transform: scale(1);
}

.num-logo {
  color: #b7acd8;
  font-size: 28px;
  transition: transform 0.1s;
  transform: scale(1);
}

.mail-top
/* .mail-link, */
/* .mail-logo */ {
  width: 207px;
}

.num-top
/* .mail-link, */
/* .mail-logo */ {
  width: 172px;
}

.loc-logo-clr {
  width: 28px;
  height: 34px;
  display: none;
}
.loc-logo-prp {
  width: 28px;
  height: 34px;
  display: inline;
}

.mail-link {
  color: #686868;
  /* padding-left: 15px; */
  font-size: 14px;
}

.num-link {
  color: #686868;
  /* padding-left: 15px; */
  font-size: 14px;
}

.num-logo:hover {
  color: #0275d8;
  /* transition: transform 0.1s; */
  /* transform: scale(1.2); */
}

.num-link:hover {
  /* padding-left: 18px; */
  /* font-size: 14.4px; */
  color: #0275d8;
}

.num-text:hover,
num-logo:hover {
  color: #0275d8;
}

.num-top:hover .num-logo {
  color: #0275d8;
}

.loc-link {
  color: #686868;
  /* padding-left: 15px; */
  font-size: 14px;
}

.loc-logo:hover .loc-logo-prp {
  display: none;
  /* transition: transform 0.1s; */
  /* transform: scale(1.2); */
}
.loc-logo:hover .loc-logo-clr {
  display: inline;
  /* transition: transform 0.1s; */
  /* transform: scale(1.2); */
}

.loc-top:hover .loc-logo-prp {
  display: none;
  /* transition: transform 0.1s; */
  /* transform: scale(1.2); */
}
.loc-top:hover .loc-logo-clr {
  display: inline;
  /* transition: transform 0.1s; */
  /* transform: scale(1.2); */
}

.loc-link:hover {
  /* padding-left: 18px; */
  /* font-size: 14.4px; */
  color: #34a853;
}

.mail-logo:hover {
  color: #d44638;
  /* transition: transform 0.1s; */
  /* transform: scale(1.2); */
}

.mail-top:hover .mail-logo {
  color: #d44638;
}
.mail-link:hover {
  /* padding-left: 18px; */
  /* font-size: 14.4px; */
  color: #d44638;
}

.linkedin {
  font-size: 30px;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  color: #b7acd8;
  transition: transform 0.1s;
  transform: scale(1);
}

.linkedin:hover {
  color: #2867b2;
  transition: transform 0.1s;
  transform: scale(1.2);
}

.insta {
  font-size: 30px;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  color: #b7acd8;
  transition: transform 0.1s;
  transform: scale(1);
}

.insta:hover {
  color: #c13584;
  transition: transform 0.1s;
  transform: scale(1.2);
}

.fb {
  font-size: 30px;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  color: #b7acd8;
  transition: transform 0.1s;
  transform: scale(1);
}

.fb:hover {
  color: #4267b2;
  transition: transform 0.1s;
  transform: scale(1.2);
}

.twitter {
  font-size: 30px;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  color: #b7acd8;
  transition: transform 0.1s;
  transform: scale(1);
}

.twitter:hover {
  color: #1da1f2;
  transition: transform 0.1s;
  transform: scale(1.2);
}

.footer-text {
  font-size: 16px;
}

.font-class {
  font-size: 14px;
}

@media screen and (max-width: 1000px) {
  .know-more,
  .games-library,
  .privacy,
  .bring-your-friends {
    width: auto;
    height: 350px;
    margin-right: auto;
    margin-left: auto;
  }

  .socials-media {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    margin-top: 0;
  }

  .loc-link {
    font-size: 12px;
  }

  .socials-mail {
    margin-left: auto;
    margin-right: auto;
  }

  .social-tag {
    margin-right: 0px;
    margin-top: 10px;
  }

  .footer-text {
    font-size: 13px;
  }
  .font-class {
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .know-more,
  .games-library,
  .privacy,
  .bring-your-friends {
    width: auto;
    height: 350px;
    margin-right: auto;
    margin-left: auto;
  }

  .know-more:hover,
  .games-library:hover,
  .privacy:hover,
  .bring-your-friends:hover {
    background-color: #8a78c0;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 0.2s;
    transform: scale(1.03);
    width: auto;
  }

  .know-head,
  .gl-head,
  .privacy-head,
  .byf-head {
    font-size: 23px;
  }

  .socials-media {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    margin-top: 0;
  }

  .loc-link {
    font-size: 12px;
  }

  .socials-mail {
    margin-left: auto;
    margin-right: auto;
  }

  .social-tag {
    margin-right: 0px;
    margin-top: 10px;
  }

  .footer-text {
    font-size: 13px;
  }
  .font-class {
    font-size: 10px;
  }
}
