* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.think-class {
  width: 100px;
  height: 150px;
}

#left-think {
  animation: forLeftThink 3s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}

#right-think {
  animation: forRightThink 3s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}

#bottom-think {
  animation: forBottomThink 3s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forLeftThink {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes forRightThink {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(360deg);
  }
}

@keyframes forBottomThink {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

#shoes {
  animation: forShoes 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forShoes {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(-5deg);
  }
}

#hand {
  animation: forHand 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forHand {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(4deg);
  }
}

#face {
  animation: forFace 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forFace {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(-10deg);
  }
}

@media screen and (max-width: 480px) {
  .think-class {
    width: 100px;
    height: 150px;
  }
}
