* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.w-class {
  width: 150px;
  height: 100px;
}

#lines {
  animation: forLines 2s linear infinite alternate;
  /* transform-box: fill-box;
  transform-origin: center; */
}

@keyframes forLines {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(40deg);
  }
}

#hand {
  animation: forHand 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forHand {
  from {
    transform: rotateZ(-5deg);
  }
  to {
    transform: rotateZ(8deg);
  }
}

#filled-square {
  animation: forFSq 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forFSq {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-50deg);
  }
}

#empty-square {
  animation: forESq 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forESq {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-20deg);
  }
}

@media screen and (max-width: 480px) {
  .w-class {
    width: 100px;
    height: 110px;
  }
}
