.main {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.nav-in-main {
  width: 87%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 480px) {
  .main {
    width: 100%;
    /* background-color: crimson; */
    margin-left: auto;
    margin-right: auto;
  }
  .nav-in-main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1800px) {
  .main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-in-main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
