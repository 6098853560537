* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.pg-class {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 480px) {
  .cartoon-class {
    width: 300px;
    height: 200px;
    /* margin-left: auto; */
    /* margin-right: 100px; */
  }
}
