.wwr-class {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 200px;
  width: 80%;
  /* background-color: firebrick; */
}

.upper-div-wwr {
  width: 600px;
  /* margin-left: auto;
  margin-right: auto; */
  margin-top: auto;
  margin-bottom: auto;
}

.title-wwr,
.descForIntro-wwr {
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
}

.img-upper {
  width: 700px;
  height: auto;
  min-width: auto;
  max-width: auto;
  margin-top: auto;
  margin-bottom: auto;
  /* height: 400px; */
}

@media screen and (max-width: 1800px) {
  .wwr-class {
    width: 90%;
  }
}

@media screen and (max-width: 1600px) {
  .wwr-class {
    width: 93%;
  }

  .upper-div-wwr {
    width: 43%;
    /* margin-top: 0px; */
    margin-top: auto;
    margin-bottom: auto;

    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .img-upper {
    width: 590px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 1300px) {
  .wwr-class {
    width: 98%;
  }

  .upper-div-wwr {
    width: 43%;
    /* margin-top: 0px; */
    margin-top: auto;
    margin-bottom: auto;

    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .img-upper {
    width: 500px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 1100px) {
  .wwr-class {
    width: 98%;
  }

  .upper-div-wwr {
    width: 43%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .img-upper {
    width: 500px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 1000px) {
  .wwr-class {
    flex-direction: column;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .upper-div-wwr {
    width: 100%;
    margin-top: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .title-wwr,
  .descForIntro-wwr {
    text-align: center;
  }
  /* 
  .img-upper {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  } */

  .img-upper {
    margin-right: auto;
    /* background-color: coral; */
    margin-left: auto;
    width: 300px;
    height: 180px;
  }
}

@media screen and (max-width: 800px) {
  .wwr-class {
    flex-direction: column;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .upper-div-wwr {
    width: 100%;
    margin-top: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .title-wwr,
  .descForIntro-wwr {
    text-align: center;
  }

  /* .img-upper {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  } */

  .img-upper {
    margin-right: auto;
    /* background-color: coral; */
    margin-left: auto;
    width: 300px;
    height: 180px;
  }
}

@media screen and (max-width: 600px) {
  .wwr-class {
    flex-direction: column;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .upper-div-wwr {
    width: 100%;
    margin-top: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .title-wwr,
  .descForIntro-wwr {
    text-align: center;
  }

  /* .img-upper {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  } */

  .img-upper {
    margin-right: auto;
    /* background-color: coral; */
    margin-left: auto;
    width: 300px;
    height: 180px;
  }
}

@media screen and (max-width: 480px) {
  .wwr-class {
    flex-direction: column;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .upper-div-wwr {
    width: 100%;
    margin-top: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .title-wwr,
  .descForIntro-wwr {
    text-align: center;
  }

  /* .img-upper {
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  } */

  .img-upper {
    margin-right: auto;
    /* background-color: coral; */
    margin-left: auto;
    width: 300px;
    height: 180px;
  }
}
