.list-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* padding: 10px; */
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
}

.resp-hr {
  border-left: 1px solid #b7acd8;
  height: 180px;
  display: block;
}

.hr-hor {
  display: none;
}

.table-of-tech {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* // flexWrap: "wrap", */
  flex-direction: row;
  overflow: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-of-tech::-webkit-scrollbar {
  display: none;
}

.tech-dev,
.des {
  cursor: pointer;
  width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #b7acd8;
  border-radius: 4px;
  margin-right: auto;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-header :hover {
  transition: transform 0.2s ease-in-out;
  transform: scale(1.03);
}

.main-div {
  display: flex;
  flex-direction: row;
}

.heading {
  display: flex;
  flex-direction: column;
  width: auto;
  margin: auto;
}

.padding-for-cards {
  padding: 40px;
}

.image-hw {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 480px) {
  .main-div {
    display: flex;
    flex-direction: column;
    /* height: 700px; */
  }

  .techanddev {
    width: auto;
    height: 1000px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }

  .resp-hr {
    display: none;
  }

  .hr-hor {
    display: block;
  }

  .table-of-tech {
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 20px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .heading {
    width: auto;
  }

  .padding-for-cards {
    padding: 15px;
  }

  .image-hw {
    width: 50px;
    height: 50px;
  }
}
