.card3 {
  width: 1000px;
  height: 800px;
  padding: 15px;
  /* background: rgb(235, 233, 241); */
  /* background: rgb(235, 233, 241); */
  border-radius: 22px;
  /* background-size: cover; */
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  /* border: 2px solid #9533be; */
  position: relative;
}

.card3:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

.proj-div {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  text-align: center;
  width: 100%;
}

.proj-title {
  height: 50px;
  font-size: 36px;
}

.data-div {
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
}

.proj-p {
  /* position: absolute; */
  top: 40%;
  left: 0%;
  transform: translate(0%, -40%);
  text-align: center;
  width: 80%;
  height: 450px;
  padding-top: 150px;
  padding-bottom: 200px;
  margin: 0;
  font-size: 18px;
  /* margin-top: 200px; */
  margin-right: auto;
  margin-left: auto;
}

/* .div-flex {
  display: flex;
  width: 100%;
} */

.btn-port-3 {
  border: 1px solid transparent;
  background-color: transparent;
  margin-right: 20px;
  font-size: 24px;
  outline: none;
  animation: notScaling2 1s 1 normal forwards;
}

.back-btn-port {
  border: 1px solid transparent;
  background-color: transparent;
  margin-right: 20px;
  font-size: 24px;
  color: #9533be;
  outline: none;
  animation: notScaling2 1s 1 normal forwards;
}

.btn-menn-div {
  width: 1000px;
  margin-left: auto;
  text-align: right;
  margin-top: 0px;
  margin-right: 20px;
}

.btn-port-disabled-3 {
  border: 1px solid grey;
  border-radius: 22px;
  background-color: transparent;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: grey;
  outline: none;
}

.btn-port-3,
.back-btn-port:hover {
  outline: none;
  /* border: 1px solid #9533be; */
  /* background-color: #9533be; */
  /* color: #fff; */
  animation: scaling2 1s 1 normal forwards;
}

.btn-port-3,
.back-btn-port:focus {
  outline: none;
}

.proj-tabs {
  width: 80%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
}

.proj-plat {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
  width: 300px;
  justify-content: space-evenly;
  height: 450px;
}

.proj-technologies {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* width: 300px; */
  justify-content: space-evenly;
  padding-top: 200px;
  padding-bottom: 200px;
  height: 450px;
}

.proj-img-web {
  width: 80%;
  height: 450px;
  border-radius: 12px;
}

.proj-img-mob {
  width: 230px;
  height: 450px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}

.left-svg {
  width: 30px;
  height: 30px;
}

.right-svg {
  width: 30px;
  height: 30px;
}

.left-svg-dis {
  width: 30px;
  height: 30px;
  color: grey;
}

.btn-proj {
  border: 1px solid transparent;
  border-radius: 22px;
  background-color: transparent;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #9533be;
  outline: none;
}

.btn-proj-disabled {
  border: 1px solid transparent;
  border-radius: 22px;
  background-color: transparent;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: grey;
  outline: none;
}

.btn-proj:hover {
  outline: none;
  /* border: 1px solid #9533be; */
  /* background-color: #9533be; */
  color: #fff;
}

.btn-proj:focus {
  outline: none;
}

.fr-h2 {
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  /* color: rgb(161, 159, 159); */
  /* text-decoration: underline; */
  font-size: small;
  border: 1px solid;
  /* background-color: #fff; */
  padding: 10px;
  border-radius: 11px;
  width: 140px;
  animation: notScaling 1s 1 normal forwards;
}

.fr-h2-scale {
  animation: scaling 1s 1 normal forwards;
}

.fr-h2:active {
  transform: scale(1.1);
}

.fr-h2:hover {
  animation: scaling 1s 1 normal forwards;
}

@keyframes scaling {
  from {
    transform: scale(1);
    border-radius: 11px;
  }
  to {
    transform: scale(1.1);
    border-radius: 22px;
  }
}

@keyframes notScaling {
  from {
    transform: scale(1.05);
    border-radius: 15px;
  }
  to {
    transform: scale(1);
    border-radius: 11px;
  }
}

@keyframes scaling2 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes notScaling2 {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (max-width: 1800px) {
  .card3 {
    width: 900px;
    height: 700px;
    padding: 15px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 150px;
    font-size: 14px;
    /* padding: 15px; */
  }

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 20px;
    font-size: 22px;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 900px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .proj-tabs {
    width: 85%;
  }
}

@media screen and (max-width: 1600px) {
  .card3 {
    width: 800px;
    height: 600px;
    padding: 15px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 150px;
    font-size: 14px;
    /* padding: 15px; */
  }

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 20px;
    font-size: 20px;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 800px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .proj-tabs {
    width: 85%;
  }

  .proj-div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 100%;
  }

  .proj-title {
    height: 30px;
    font-size: 28px;
  }

  .data-div {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }

  .proj-p {
    /* position: absolute; */
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 80%;
    height: 300px;
    padding-top: 60px;
    padding-bottom: 150px;
    margin: 0;
    /* margin-top: 200px; */
    margin-right: auto;
    margin-left: auto;
  }

  /* .div-flex {
  display: flex;
  width: 100%;
} */

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 15px !important;
    font-size: 16px !important;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 800px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .btn-port-disabled-3 {
    border: 1px solid grey;
    border-radius: 22px;
    background-color: transparent;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: grey;
    outline: none;
  }

  .btn-port-3,
  .back-btn-port:hover {
    outline: none;
    /* border: 1px solid #9533be; */
    /* background-color: #9533be; */
    /* color: #fff; */
    animation: scaling2 1s 1 normal forwards;
  }

  .btn-port-3,
  .back-btn-port:focus {
    outline: none;
  }

  .fr-h2 {
    width: 110px;
    font-size: 10px;
    padding: 8px;
    /* border-radius: 22px; */
  }

  .proj-tabs {
    width: 80%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-evenly;
  }

  .proj-plat {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 150px;
    padding-bottom: 150px;
    width: 300px;
    justify-content: space-evenly;
    height: 350px;
  }

  .proj-technologies {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    padding-top: 150px;
    padding-bottom: 150px;
    height: 350px;
  }

  .proj-img-web {
    width: 80%;
    height: 350px;
    border-radius: 12px;
  }

  .proj-img-mob {
    width: 180px;
    height: 350px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
}

@media screen and (max-width: 1500px) {
  .btn-port-3,
  .back-btn-port {
    font-size: 14px !important;
    margin-right: 10px !important;
  }
}

@media screen and (max-width: 1300px) {
  .card3 {
    width: 700px;
    height: 500px;
    padding: 15px;
  }

  .btn-port-2 {
    /* margin-top: 25px; */
    width: 150px;
    font-size: 14px;
    /* padding: 15px; */
  }

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 10px !important;
    font-size: 13px !important;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 700px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .proj-tabs {
    width: 80%;
    margin-top: 50px;
  }

  .proj-div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 100%;
  }

  .proj-title {
    height: 30px;
    font-size: 20px;
  }

  .data-div {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }

  .proj-p {
    /* position: absolute; */
    top: 40%;
    left: 0%;
    transform: translate(0%, -40%);
    text-align: center;
    width: 80%;
    height: 250px;
    padding-top: 80px;
    padding-bottom: 100px;
    margin: 0;
    /* margin-top: 200px; */
    margin-right: auto;
    margin-left: auto;
    font-size: 14px;
  }

  /* .div-flex {
  display: flex;
  width: 100%;
} */

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 8px !important;
    font-size: 14px !important;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 700px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .btn-port-disabled-3 {
    border: 1px solid grey;
    border-radius: 22px;
    background-color: transparent;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: grey;
    outline: none;
  }

  .btn-port-3,
  .back-btn-port:hover {
    outline: none;
    /* border: 1px solid #9533be; */
    /* background-color: #9533be; */
    /* color: #fff; */
    animation: scaling2 1s 1 normal forwards;
  }

  .btn-port-3,
  .back-btn-port:focus {
    outline: none;
  }

  .fr-h2 {
    width: 90px;
    padding: 7px;
    font-size: 8px;
  }

  .proj-plat {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 250px;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 300px;
    justify-content: space-evenly;
  }

  .proj-technologies {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 250px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .proj-img-web {
    width: 70%;
    height: 280px;
    border-radius: 12px;
  }

  .proj-img-mob {
    width: 135px;
    height: 270px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .card3 {
    width: 600px;
    height: 400px;
    padding: 15px;
    border-radius: 14px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 150px;
    font-size: 14px;
    /* padding: 15px; */
  }

  .proj-tabs {
    width: 80%;
  }

  .proj-div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 100%;
  }

  .proj-title {
    height: 20px;
    font-size: 18px;
  }

  .data-div {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }

  .proj-p {
    /* position: absolute; */
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 80%;
    height: 150px;
    padding-top: 40px;
    padding-bottom: 50px;
    margin: 0;
    /* margin-top: 200px; */
    margin-right: auto;
    margin-left: auto;
    font-size: 12px;
  }

  /* .div-flex {
  display: flex;
  width: 100%;
} */

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 4px !important;
    font-size: 16px !important;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 600px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .btn-port-disabled-3 {
    border: 1px solid grey;
    border-radius: 22px;
    background-color: transparent;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: grey;
    outline: none;
  }

  .btn-port-3,
  .back-btn-port:hover {
    outline: none;
    /* border: 1px solid #9533be; */
    /* background-color: #9533be; */
    /* color: #fff; */
    animation: scaling2 1s 1 normal forwards;
  }

  .btn-port-3,
  .back-btn-port:focus {
    outline: none;
  }

  .fr-h2 {
    width: 80px;
    padding: 5px;
    font-size: 8px;
  }

  .proj-plat {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 150px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 300px;
    justify-content: space-evenly;
  }

  .proj-technologies {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 150px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .proj-img-web {
    width: 60%;
    height: 150px;
    border-radius: 12px;
  }

  .proj-img-mob {
    width: 100px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
}

@media screen and (max-width: 1000px) {
  .card3 {
    width: 560px;
    height: 360px;
    padding: 15px;
    border-radius: 14px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 150px;
    font-size: 14px;
    /* padding: 15px; */
  }

  .proj-tabs {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .proj-div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 100%;
  }

  .proj-title {
    height: 20px;
    font-size: 17px;
  }

  .data-div {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }

  .proj-p {
    /* position: absolute; */
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 80%;
    height: 220px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0;
    /* margin-top: 200px; */
    margin-right: auto;
    margin-left: auto;
    font-size: 11px;
  }

  /* .div-flex {
  display: flex;
  width: 100%;
} */

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 8px !important;
    font-size: 8px !important;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 560px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .btn-port-disabled-3 {
    border: 1px solid grey;
    border-radius: 22px;
    background-color: transparent;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: grey;
    outline: none;
  }

  .btn-port-3,
  .back-btn-port:hover {
    outline: none;
    /* border: 1px solid #9533be; */
    /* background-color: #9533be; */
    /* color: #fff; */
    animation: scaling2 1s 1 normal forwards;
  }

  .btn-port-3,
  .back-btn-port:focus {
    outline: none;
  }

  .fr-h2 {
    width: 80px;
    padding: 5px;
    font-size: 7px;
  }

  .proj-plat {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 220px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 300px;
    justify-content: space-evenly;
  }

  .proj-technologies {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 220px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .proj-img-web {
    width: 60%;
    height: 220px;
    border-radius: 12px;
  }

  .proj-img-mob {
    width: 120px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
}

@media screen and (max-width: 800px) {
  .card3 {
    width: 460px;
    height: 280px;
    padding: 15px;
    border-radius: 14px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 150px;
    font-size: 14px;
    /* padding: 15px; */
  }

  .proj-tabs {
    width: 85%;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .proj-div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 100%;
  }

  .proj-title {
    height: 15px;
    font-size: 12px;
  }

  .data-div {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }

  .proj-p {
    /* position: absolute; */
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 80%;
    height: 150px;
    padding-top: 30px;
    padding-bottom: 50px;
    margin: 0;
    /* margin-top: 200px; */
    margin-right: auto;
    margin-left: auto;
    font-size: 8px;
  }

  /* .div-flex {
  display: flex;
  width: 100%;
} */

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 5px !important;
    font-size: 8px !important;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 460px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .btn-port-disabled-3 {
    border: 1px solid grey;
    border-radius: 22px;
    background-color: transparent;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: grey;
    outline: none;
  }

  .btn-port-3,
  .back-btn-port:hover {
    outline: none;
    /* border: 1px solid #9533be; */
    /* background-color: #9533be; */
    /* color: #fff; */
    animation: scaling2 1s 1 normal forwards;
  }

  .btn-port-3,
  .back-btn-port:focus {
    outline: none;
  }

  .fr-h2 {
    width: 70px;
    padding: 3px;
    font-size: 6px;
  }

  .proj-plat {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 150px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 300px;
    justify-content: space-evenly;
  }

  .proj-technologies {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 150px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .proj-img-web {
    width: 60%;
    height: 150px;
    border-radius: 12px;
  }

  .proj-img-mob {
    width: 90px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
}

@media screen and (max-width: 600px) {
  .card3 {
    width: 340px;
    height: 220px;
    padding: 15px;
    border-radius: 14px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 150px;
    font-size: 14px;
    /* padding: 15px; */
  }

  .proj-tabs {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .proj-div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 100%;
  }

  .proj-title {
    height: 10px;
    font-size: 8px;
  }

  .data-div {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }

  .proj-p {
    /* position: absolute; */
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    width: 80%;
    height: 130px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0;
    /* margin-top: 200px; */
    margin-right: auto;
    margin-left: auto;
    font-size: 5px;
  }

  /* .div-flex {
  display: flex;
  width: 100%;
} */

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 7px !important;
    font-size: 10px !important;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 340px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .btn-port-disabled-3 {
    border: 1px solid grey;
    border-radius: 22px;
    background-color: transparent;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: grey;
    outline: none;
  }

  .btn-port-3,
  .back-btn-port:hover {
    outline: none;
    /* border: 1px solid #9533be; */
    /* background-color: #9533be; */
    /* color: #fff; */
    animation: scaling2 1s 1 normal forwards;
  }

  .btn-port-3,
  .back-btn-port:focus {
    outline: none;
  }

  .fr-h2 {
    width: 45px;
    padding: 2px;
    font-size: 4px;
  }

  .proj-plat {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 130px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 300px;
    justify-content: space-evenly;
  }

  .proj-technologies {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 130px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .proj-img-web {
    width: 200px;
    height: 130px;
    border-radius: 12px;
  }

  .left-svg {
    width: 10px;
    height: 10px;
  }

  .left-svg-dis {
    width: 10px;
    height: 10px;
    color: grey;
  }

  .proj-img-mob {
    width: 75px;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
}

@media screen and (max-width: 480px) {
  .card3 {
    width: 320px;
    height: 650px;
    padding: 20px;
    border-radius: 12px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 150px;
    font-size: 14px;
    /* padding: 15px; */
  }

  .proj-div {
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    text-align: center;
    width: 100%;
  }

  .proj-title {
    height: 10px;
    font-size: 30px;
    margin-bottom: 0;
  }

  .data-div {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }

  .proj-p {
    /* position: absolute; */
    top: 50%;
    left: 0%;
    transform: translate(0%, 0%);
    text-align: center;
    width: 90%;
    height: 415px !important;
    padding-top: 20px;
    padding-bottom: 0px;
    margin: 0;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
  }

  .proj-tabs {
    width: 80%;
    margin-top: 25px;
    margin-bottom: 0;
    padding-bottom: 0;
    flex-wrap: wrap;
  }

  /* .div-flex {
  display: flex;
  width: 100%;
} */

  .btn-port-3,
  .back-btn-port {
    border: 1px solid transparent;
    background-color: transparent;
    margin-right: 2px !important;
    font-size: 22px !important;
    outline: none;
    animation: notScaling2 1s 1 normal forwards;
  }

  .btn-menn-div {
    width: 250px;
    margin-left: auto;
    text-align: right;
    margin-top: 0px;
    margin-right: 20px;
  }

  .btn-port-disabled-3 {
    border: 1px solid grey;
    border-radius: 22px;
    background-color: transparent;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: grey;
    outline: none;
  }

  .btn-port-3,
  .back-btn-port:hover {
    outline: none;
    /* border: 1px solid #9533be; */
    /* background-color: #9533be; */
    /* color: #fff; */
    animation: scaling2 1s 1 normal forwards;
  }

  .btn-port-3,
  .back-btn-port:focus {
    outline: none;
  }

  .fr-h2 {
    width: 110px;
    padding: 6px;
    font-size: 12px;
  }

  .proj-plat {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 435px;
    padding: 40px;
    padding-top: 150px;
    padding-bottom: 50px;
  }

  .proj-technologies {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 435px;
    padding: 40px;
    padding-top: 150px;
    padding-bottom: 50px;
  }

  .div-img {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 435px !important;
    padding: 40px;
    padding-top: 70px;
    padding-bottom: 50px;
  }

  .proj-img-web {
    width: 90%;
    height: 200px;
    border-radius: 8px;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .left-svg {
    width: 12px;
    height: 12px;
  }

  .left-svg-dis {
    width: 12px;
    height: 12px;
    color: grey;
  }

  .div-mob {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 300px; */
    justify-content: space-evenly;
    height: 435px;
    padding: 40px;
    padding-top: 70px;
    padding-bottom: 50px;
  }

  .proj-img-mob {
    width: 150px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
  }
}
