* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.des-class {
  width: 150px;
  height: 100px;
}

#bulb {
  animation: forBulb 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forBulb {
  from {
    transform: rotateZ(-20deg);
  }
  to {
    transform: rotateZ(20deg);
  }
}

#hand {
  animation: forHand 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forHand {
  from {
    transform: rotateZ(-5deg);
  }
  to {
    transform: rotateZ(8deg);
  }
}

#face {
  animation: forFace 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes forFace {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(-10deg);
  }
}

@media screen and (max-width: 480px) {
  .des-class {
    width: 100px;
    height: 110px;
  }
}
