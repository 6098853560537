.wwd-class {
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.upper-div-wwd {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  text-align: center;
  margin-top: 100px;
}

.title-wwd {
  text-align: center;
  margin-top: 50px;
}
.descForIntro-wwd {
  text-align: center;
}

.cards-width {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.we-think {
  width: 350px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #b7acd8;
  border-radius: 30px;
  margin-right: auto;
  cursor: pointer;
}

.we-design {
  width: 350px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #b7acd8;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.we-create {
  width: 350px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #b7acd8;
  border-radius: 30px;
  margin-left: auto;
  cursor: pointer;
}

.modal .modal-dialog .modal-content {
  background-color: transparent !important;
  border-color: transparent !important;
}

.modal-top {
  background-color: #b7acd8;
  border-radius: 30px;
  text-align: center;
}

.modal-top-2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: space-between;
  color: #fff;
}

.modal-heading {
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
}

.modal-svg {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.modal-width {
  width: 300px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #9533be !important;
  background-color: transparent !important;
  border-color: #9533be !important;
  border-bottom-color: #f4f2fb !important;
  outline: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #b7acd8;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  outline: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link :focus {
  outline: none;
}

@media screen and (max-width: 480px) {
  .wwd-class {
    width: 85%;
    margin-top: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .upper-div-wwd {
    width: 100%;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .title-wwd,
  .descForIntro-wwd {
    text-align: center;
  }

  .cards-width {
    width: 100%;
  }

  .we-think,
  .we-design,
  .we-create {
    width: auto;
    height: 150px;
    text-align: left;

    /* margin-right: auto; */
    /* margin-left: auto; */
  }

  .modal-top {
    text-align: center;
  }

  .modal-width {
    width: auto;
  }

  .modal-top-2 {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #fff;
    text-align: center;
  }

  .modal-heading {
    margin-top: 20px;
    color: #fff;
    font-weight: bold;
    margin-left: auto;
  }

  .modal-svg {
    /* margin-top: auto; */
    /* margin-bottom: auto; */
    margin-left: auto;
    margin-right: auto;
  }

  .card-inner-text,
  .card-inner-heading {
    padding-left: 10px;
    /* padding-left: 10px; */
  }
}

@media screen and (max-width: 370px) {
  .card-inner-text,
  .card-inner-heading {
    padding-left: 5px;
    /* padding-left: 10px; */
  }
}
