.intro-class {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  margin-top: 150px;
  width: 80%;
  max-width: auto;
  /* background-color: crimson; */
}

.upper-div {
  /* background-color: chocolate; */
  width: 45%;
  max-width: auto;
  min-width: auto;
  height: auto;
  /* margin-top: 100px;
   */
  margin-top: auto;
  margin-bottom: auto;
}

.title {
  text-align: left;
}

.descForIntro {
  text-align: left;
}

.btns-intro {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  /* justify-content: center; */
}

.svg-dream {
  width: 700px;
  height: auto;
  min-width: auto;
  max-width: auto;
  margin-top: auto;
  margin-bottom: auto;
  /* margin-left: auto; */
}

.tech-button {
  text-decoration: none;
  outline: none;
  border: 2px solid #9533be;
  color: #9533be;
  background-color: transparent;
  padding: 8px;
  width: 200px;
  /* height: 200px; */
  border-radius: 20px;
  height: 45px;
}

.tech-button:focus,
.queries-button:focus {
  outline: 0;
}

.purple-two-tone {
  display: inline;
}

.white-two-tone {
  display: none;
}

.tech-button:hover {
  text-decoration: none;
  display: inline;
  background-color: #a151c3;
  border-color: #a151c3;
  color: #fff;
}

.tech-button:hover .purple-two-tone {
  display: none;
}

.tech-button:hover .white-two-tone {
  display: inline;
}

.queries-button {
  text-decoration: none;
  outline: none;
  color: #fff;
  border-color: transparent;
  background-color: #9533be;
  padding: 10px;
  width: 200px;
  /* height: 100px; */
  border-radius: 20px;
  height: 45px;
  margin-left: 15px;
}
.queries-button:hover {
  text-decoration: none;
  background-color: #a151c3;
  color: #fff;
}

@media screen and (max-width: 1800px) {
  .intro-class {
    width: 80%;
  }
}

@media screen and (max-width: 1600px) {
  .intro-class {
    width: 90%;
  }
}

@media screen and (max-width: 1300px) {
  .intro-class {
    width: 90%;
  }

  .upper-div {
    width: 45%;
  }

  .svg-dream {
    width: 600px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 1100px) {
  .intro-class {
    width: 95%;
    /* background-color: crimson; */
  }

  .btns-intro {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .tech-button {
    margin-left: auto;
    margin-right: auto;
  }

  .queries-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .upper-div {
    width: 43%;
  }

  .svg-dream {
    width: 590px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 1000px) {
  .intro-class {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: center;
    margin-top: auto;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }
  .btns-intro {
    flex-direction: column;
  }

  .upper-div {
    width: 100%;
    margin-top: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .title,
  .descForIntro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }

  .svg-dream {
    width: 300px;
    height: auto;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    /* text-align: center; */
    /* justify-content: center; */
  }

  .tech-button {
    margin-left: auto;
    margin-right: auto;
  }

  .queries-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

@media screen and (max-width: 800px) {
  .intro-class {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: center;
    margin-top: auto;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .upper-div {
    width: 100%;
    margin-top: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .title,
  .descForIntro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }

  .svg-dream {
    width: 300px;
    height: auto;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    /* text-align: center; */
    /* justify-content: center; */
  }

  .tech-button {
    margin-left: auto;
    margin-right: auto;
  }

  .queries-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .intro-class {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: center;
    margin-top: auto;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .upper-div {
    width: 100%;
    margin-top: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .title,
  .descForIntro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }

  .svg-dream {
    width: 300px;
    height: auto;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    /* text-align: center; */
    /* justify-content: center; */
  }

  .tech-button {
    margin-left: auto;
    margin-right: auto;
  }

  .queries-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .intro-class {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: center;
    margin-top: auto;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .upper-div {
    width: 100%;
    margin-top: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .title,
  .descForIntro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }

  .svg-dream {
    width: 300px;
    height: auto;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    /* text-align: center; */
    /* justify-content: center; */
  }

  .tech-button {
    margin-left: auto;
    margin-right: auto;
  }

  .queries-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}
