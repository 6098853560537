.main-nav {
  margin-left: auto;
}

.nav-brand {
  margin-left: 50px;
}

.nav-second {
  margin-right: 50px;
}

.contact-button {
  outline: none;
  width: 150px;
  border: 2px solid #9533be;
  color: #9533be;
  background-color: transparent;
  padding: 8px;
  border-radius: 24px;
  height: 45px;
}

.contact-button:hover {
  background-color: #9533be;
  color: #fff;
}

.contact-button:focus {
  outline: 0;
}

.navbar .navbar-collapse .nav-second .dropdown .dropdown-menu {
  background-color: #657dc5;
  padding: 2px;
  text-align: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.animated .navbar .navbar-toggler .navbar-toggler-icon {
  /* background-color: turquoise; */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.animated .navbar .navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.animated .navbar .navbar-toggler span {
  display: block;
  /* background-color: #fff; */
  height: 2.5px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  /* left: 0; */
  opacity: 1;
}

.animated .navbar .navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform 0.35s ease-in-out;
  -moz-transition: transform 0.35s ease-in-out;
  -o-transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
}

.animated .navbar .navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  /* left: 12px; */
  top: 45px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.animated .navbar .navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.animated .navbar .navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  /* left: 12px; */
  top: 45px;
  /* bottom: auto; */
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

.intro,
.what-we-deal-in,
.who-we-are,
.why-us,
.portfolio {
  color: #000;
  margin-right: 50px;
}

@media screen and (max-width: 1000px) {
  .nav-second {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    /* margin-right: 20px; */
  }

  .intro,
  .what-we-deal-in,
  .who-we-are,
  .why-us,
  .portfolio {
    color: #000;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 480px) {
  .main-nav {
    margin-left: auto;
    margin-right: auto;
  }

  .nav-brand {
    margin-left: 20px;
  }

  .nav-second {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    /* margin-right: 20px; */
  }

  .intro,
  .what-we-deal-in,
  .who-we-are,
  .why-us,
  .portfolio {
    color: #000;
    margin-left: auto;
    margin-right: auto;
  }
}
