/* .App {
  font-family: sans-serif;
  text-align: center;
} */

.bg {
  position: absolute;
  width: 80%;
  height: 430px;
  background-size: cover;
  border-radius: 12px;
  opacity: 1;
  top: 30%;
  left: 0%;
  transform: translate(12%, -30%);
}

.bg-mob {
  width: 230px;
  height: 450px;
  position: absolute;
  background-size: cover;
  border-radius: 12px;
  opacity: 1;
  top: 30%;
  left: 29%;
  transform: translate(41%, -30%);
}

@media screen and (max-width: 1800px) {
  .bg {
    position: absolute;
    width: 80%;
    height: 430px;
    background-size: cover;
    border-radius: 12px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(12%, -30%);
  }

  .bg-mob {
    width: 230px;
    height: 450px;
    position: absolute;
    background-size: cover;
    border-radius: 12px;
    opacity: 1;
    top: 30%;
    left: 26%;
    transform: translate(42%, -30%);
  }
}

@media screen and (max-width: 1600px) {
  .bg {
    position: absolute;
    width: 80%;
    height: 350px;
    background-size: cover;
    border-radius: 12px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(12%, -30%);
  }

  .bg-mob {
    width: 200px;
    height: 370px;
    position: absolute;
    background-size: cover;
    border-radius: 12px;
    opacity: 1;
    top: 30%;
    left: 26%;
    transform: translate(45%, -30%);
  }
}

@media screen and (max-width: 1300px) {
  .bg {
    position: absolute;
    width: 80%;
    height: 280px;
    background-size: cover;
    border-radius: 10px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(12%, -30%);
  }

  .bg-mob {
    width: 180px;
    height: 320px;
    position: absolute;
    background-size: cover;
    border-radius: 10px;
    opacity: 1;
    top: 30%;
    left: 26%;
    transform: translate(43%, -30%);
  }
}

@media screen and (max-width: 1100px) {
  .bg {
    position: absolute;
    width: 60%;
    height: 170px;
    background-size: cover;
    border-radius: 8px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(33%, -30%);
  }

  .bg-mob {
    width: 100px;
    height: 180px;
    position: absolute;
    background-size: cover;
    border-radius: 8px;
    opacity: 1;
    top: 30%;
    left: 34%;
    transform: translate(45%, -30%);
  }
}

@media screen and (max-width: 1000px) {
  .bg {
    position: absolute;
    width: 80%;
    height: 220px;
    background-size: cover;
    border-radius: 7px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(12%, -30%);
  }

  .bg-mob {
    width: 130px;
    height: 230px;
    position: absolute;
    background-size: cover;
    border-radius: 6px;
    opacity: 1;
    top: 30%;
    left: 29%;
    transform: translate(41%, -30%);
  }
}

@media screen and (max-width: 800px) {
  .bg {
    position: absolute;
    width: 80%;
    height: 155px;
    background-size: cover;
    border-radius: 6px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(12%, -30%);
  }

  .bg-mob {
    width: 80px;
    height: 155px;
    position: absolute;
    background-size: cover;
    border-radius: 5px;
    opacity: 1;
    top: 30%;
    left: 33%;
    transform: translate(46%, -30%);
  }
}

@media screen and (max-width: 600px) {
  .bg {
    position: absolute;
    width: 200px;
    height: 130px;
    background-size: cover;
    border-radius: 5px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(12%, -30%);
  }

  .bg-mob {
    width: 80px;
    height: 155px;
    position: absolute;
    background-size: cover;
    border-radius: 5px;
    opacity: 1;
    top: 30%;
    left: 29%;
    transform: translate(41%, -30%);
  }
}

@media screen and (max-width: 480px) {
  .bg {
    position: absolute;
    margin-left: auto;
    width: 95%;
    height: 200px;
    background-size: cover;
    border-radius: 4px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(2.5%, -30%);
  }

  .bg-mob {
    margin-top: 20px;
    width: 210px;
    height: 400px;
    position: absolute;
    background-size: cover;
    border-radius: 6px;
    opacity: 1;
    top: 30%;
    left: 0%;
    transform: translate(27%, -30%);
  }
}
