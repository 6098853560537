.card2 {
  width: 350px;
  height: 350px;
  padding: 15px;
  /* background: rgb(235, 233, 241); */
  /* background: #fff2da; */
  /* /* background: rgb(42, 42, 42); */
  border-radius: 22px;
  /* background-size: cover; */
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 2px solid 9533be;
  position: relative;
  opacity: 0.9;
}

.card2:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

.details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* border: 1px solid #fff2da; */
  /* background-color: #fff2da; */
  /* box-shadow: 20px 20px 50px rgb(178, 174, 190); */
  border-radius: 22px;
  opacity: 0.9;
  /* padding: 50px; */
  /* width: 100%; */
  /* height: 70vh; */
}

.first-proj-title {
  margin-bottom: 0;
  padding: 0;
  opacity: 1;
}

.first-proj-p {
  margin-top: 5px;
  margin-bottom: 0;
  padding: 0;
  opacity: 1;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.btn-port-2 {
  margin-top: 30px;
  /* border: 1px solid #9533be; */
  border-radius: 24px;
  background-color: transparent;
  padding: 13px;
  margin-left: 5px;
  margin-right: 5px;
  /* color: #9533be; */
  outline: none;
  animation: notScaling 1s 1 normal forwards;
}

.btn-port-disabled-2 {
  border: 1px solid grey;
  border-radius: 22px;
  background-color: transparent;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: grey;
  outline: none;
}

.btn-port-2:hover {
  animation: scaling 1s 1 normal forwards;
}

.btn-port-2:focus {
  outline: none;
}

@keyframes scaling {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes notScaling {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (max-width: 1800px) {
  .card2 {
    width: 300px;
    height: 320px;
    padding: 14px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 150px !important;
    font-size: 14px !important;
    /* padding: 15px; */
  }
}

@media screen and (max-width: 1600px) {
  .card2 {
    width: 280px;
    height: 305px;
    padding: 14px;
  }

  .btn-port-2 {
    margin-top: 25px;
    width: 140px !important;
    font-size: 12px !important;
    padding: 13px !important;
  }
}

@media screen and (max-width: 1300px) {
  .card2 {
    width: 240px;
    height: 265px;
    padding: 14px;
  }

  .first-proj-title {
    font-size: 18px;
    margin-bottom: 0;
    padding: 0;
  }

  .first-proj-p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    font-size: 14px;
    margin-bottom: 0;
    padding: 0;
    width: 160px;
  }

  .btn-port-2 {
    margin-top: 30px;
    width: 140px !important;
    font-size: 12px !important;
    padding: 10px !important;
  }
}

@media screen and (max-width: 1100px) {
  .card2 {
    width: 220px;
    height: 240px;
    padding: 15px;
  }

  .first-proj-title {
    font-size: 16px;
    margin-bottom: 0;
    padding: 0;
  }

  .first-proj-p {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    margin-bottom: 0;
    padding: 0;
    width: 160px;
  }

  .btn-port-2 {
    width: 120px !important;
    font-size: 10px !important;
    padding: 8px !important;
  }
}

@media screen and (max-width: 1000px) {
  .card2 {
    width: 200px;
    height: 215px;
    padding: 15px;
    border-radius: 14px;
  }

  .first-proj-title {
    font-size: 16px;
    margin-bottom: 0;
    padding: 0;
  }

  .first-proj-p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 0;
    width: 150px;
    padding: 0;
    width: 140px;
  }

  .btn-port-2 {
    width: 100px !important;
    font-size: 9px !important;
    padding: 8px !important;
  }
}

@media screen and (max-width: 800px) {
  .card2 {
    width: 155px;
    height: 165px;
    padding: 30px;
    border-radius: 14px;
  }

  .first-proj-title {
    font-size: 12px;
    margin-bottom: 0;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .details {
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    text-align: center;
    width: 155px;
    /* border: 1px solid #fff2da; */
    /* background-color: #fff2da; */
    /* box-shadow: 20px 20px 50px rgb(178, 174, 190); */
    border-radius: 16px;
    opacity: 0.9;
    /* padding: 50px; */
    /* width: 100%; */
    /* height: 70vh; */
  }

  .first-proj-p {
    font-size: 8px;
    margin-bottom: 0;
    width: 90px;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-port-2 {
    width: 80px !important;
    font-size: 7px !important;
    padding: 6px !important;
  }
}

@media screen and (max-width: 600px) {
  .card2 {
    width: 110px;
    height: 120px;
    padding: 50px;
    border-radius: 14px;
  }

  .first-proj-title {
    font-size: 10px;
    margin-bottom: 0;
    padding: 0;
  }

  .first-proj-p {
    margin-left: auto;
    margin-right: auto;
    font-size: 6px;
    margin-bottom: 0;
    padding: 0;
  }

  .btn-port-2 {
    margin-top: 10px;
    width: 60px !important;
    padding: 4px !important;
    font-size: 5px !important;
  }
}

@media screen and (max-width: 480px) {
  .card2 {
    width: 220px;
    height: 230px;
    padding: 100px;
    border-radius: 14px;
  }

  .details {
    position: absolute;
    /* top: 25%; */
    /* left: 25%; */
    transform: translate(0%, 0%);
    text-align: center;
    width: 100%;
    /* border: 1px solid #fff2da; */
    /* background-color: #fff2da; */
    /* box-shadow: 20px 20px 50px rgb(178, 174, 190); */
    border-radius: 16px;
    opacity: 0.9;
    /* padding: 50px; */
    /* width: 100%; */
    /* height: 70vh; */
  }

  .first-proj-title {
    font-size: 22px;
    margin-bottom: 0;
    margin-top: 50px;
    padding: 0;
  }

  .first-proj-p {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0;
  }

  .btn-port-2 {
    margin-top: 15px;
    width: 150px !important;
    padding: 5px !important;
    font-size: 13px !important;
  }
}
